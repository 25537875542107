import { Grid, useMediaQuery, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { City, Country, State } from "country-state-city";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import "./App.css";
import KenButton from "./GlobalComponents/KenButton";
import KenInput from "./GlobalComponents/KenInput";
import KenLoader from "./GlobalComponents/KenLoader/index";
import KenPhoneInput from "./GlobalComponents/KenPhoneInput";
import KenSelect from "./GlobalComponents/KenSelect";
import { SendOTP } from "./Utils/apiServices";
import VerifyOTP from "./VerifyOTP";
import formData from "./formData.json";
import phone from 'phone';
import _  from "lodash";
import PerfectScrollbar from 'react-perfect-scrollbar';
import nepalFormData from './nepalFormData.json'

const useStyles = makeStyles((theme) => ({
  phoneContainer: {
    display: "flex",
    "& .phone-input": {
      fontFamily: "Poppins,sans-serif",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "100%",
      border: "1px solid #F5F7F8",
      // color: '#505F79',
      padding: "16px",
      order: 2,
      // background: '#F3F5F7',
      // border: 0,
      height: "57px !important",
      width: "100% !important",
      //   57
      borderRadius: "4px",
      marginLeft: "11px",
      "&::placeholder": {
        fontSize: "16px",
        lineHeight: "100%",
        // color: '#A8AFBC',
        border: "1px solid #F5F7F8",

        fontFamily: "Poppins,sans-serif",
      },
    },
    "& .phone-dropdown": {
      position: "relative",
      order: 1,
      // border: 0,
      border: "1px solid #F5F7F8",

      // background: '#F3F5F7',
      "&.open": {
        // background: '#F3F5F7',
        "& .selected-flag": {
          // background: '#F3F5F7',
        },
      },
      "& .selected-flag": {
        width: "50px",
        padding: "10px",
        background: "#FFFFFF",
        border: "1px solid #F5F7F8",
        "&:hover": {
          // background: '#F3F5F7',
        },
      },
    },
  },
  form: {
    width: '20vw',  
    margin: '0 auto',
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
    },
    [`@media (min-width: 400px) and (max-width: 700px)`]: {
      width: '60vw',
    },
    [`@media (min-width: 350px) and (max-width: 400px)`]: {
      width: '90vw',
    },
  },
  formBox: {
    color: "#fff",
    display: "flex",
    flexDirection: 'column !important',
    width: '35vw',
    backgroundColor: '#858484',
    position: 'absolute',
    right: '0px',
    top: '0px',
    height: '100vh',
    overflow: 'hidden',
    opacity: 0.8,
    '& .formContainer': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',  // Enables scrolling within this container
    },
    '& .scrollbar-container': {
      maxWidth: '500px',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '16px',
        paddingRight: '16px',
        maxWidth: '360px',
      },
    },
    [`@media (min-width: 400px) and (max-width: 700px)`]: {
      background: 'none',
      width: '100%',
      color: '#000'
    },
    [`@media (min-width: 350px) and (max-width: 400px)`]: {
      background: 'none',
      width: '100%',
      color: '#000'
    },
  },
}));

function App() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [data, setData] = useState(formData.fields);
  const [nepalFormDataState, setNepalFormDataState] = useState(nepalFormData.fields);
  const [Id, setId] = useState();
  useWatch({ name: "Interested_Level_of_Study__c", control: control });
  const isMdScreen = useMediaQuery('(min-width: 768px)');
  const images = 'https://flamestorageken42.blob.core.windows.net/flame-storage-ken42/AIMS_background.png';
  console.log(errors, "y56y5656u");
  const [isChecked, setIsChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const [qrForm, setQRForm] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');

  useEffect(() => {
    // Check if 'qr=true' and 'country=Nepal' are in the query parameters
    if (query.get('qr') === 'true' && query.get('country') === 'Nepal') {
      setQRForm(true);
      setIsDataAvailable(true);
    }
  }, []);

  useEffect(() => {
    if (query.get('utm_source') && query.get('utm_medium') && query.get('utm_campaign'))
      setUtmSource(query.get('utm_source'))
      setUtmMedium(query.get('utm_medium'))
      setUtmCampaign(query.get('utm_campaign'))
  }, [])

  console.log(qrForm, isDataAvailable, nepalFormData, "dataAvailable")
  console.log(utmSource, utmMedium, utmCampaign, 'setCampaign');

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const phoneValidatonCheck = (value) => {
    const validPhone = phone(value);
    if(validPhone.isValid == false) {
      return 'Please enter valid Phone number';
    }
    
    return true;
  }

  /*
  *   First Name & Last Name letter should be capital
  * 
  */

  const FirstName = useWatch({ name: "FirstName", control: control });
  // console.log(FirstName, "FirstName")
  const LastName = useWatch({ name: "LastName", control: control });

  useEffect(() => {
    if(FirstName) {
      const capitalizedFirstName = FirstName.charAt(0).toUpperCase() + FirstName.slice(1).toLowerCase();
      setValue('FirstName', capitalizedFirstName)
    }
  }, [FirstName])

  useEffect(() => {
    if(LastName) {
      const capitalizedLastName = LastName.charAt(0).toUpperCase() + LastName.slice(1).toLowerCase();
      setValue('LastName', capitalizedLastName)
    }
  }, [LastName])

  /*
  *   First Name & Last Name letter should be capital
  * 
  */

  const onSubmit = async (data) => {
    if (isChecked === false) {
      enqueueSnackbar("Please check the declaration box", {
        variant: "error",
      });
    } else {
      setLoader(true);
      try {
        let leadSource = (utmSource && utmMedium && utmCampaign) ? "Zollege" : "Website";
        const qrParam = query.get('qr');
        const countryParam = query.get('country');

        if (qrParam === 'true') {
          leadSource = "Exhibition Data";
          if (countryParam === 'Nepal') {
            leadSource = "Nepal Scholarship Test";
          }
        }

        const res = await SendOTP(data.Email, {
          ...data,
          LeadSource: leadSource,
          utm_source: utmSource,
          Campaign__c: utmMedium,
          Remarks__c: utmCampaign,  
        });
        setLoader(false);
        if (res?.success) {
          setShowVerifyOTP(true);
          setId(res?.data?.Id);
        } else {
          setLoader(false);
          enqueueSnackbar(res.message, {
            variant: "error",
          });
        }
      } catch (error) {
        setLoader(false);
        console.error("Error sending OTP:", error);
        enqueueSnackbar("Something went wrong while sending OTP", {
          variant: "error",
        });
      }
    }
  };

  const handleBackClick = () => {
    setShowVerifyOTP(false);
  };

  const renderFormField = (field) => {
    switch (field.type) {
      case "select":
        return renderSelect(field);
      case "phone":
        return renderPhoneInput(field);
      default:
        return renderInput(field);
    }
  };


  useEffect(() => {
    if(query.get('qr')) {
      setQRForm(true);
    }
  }, [])

  const renderSelect = (field) => {
    let customOptions = Array.isArray(field.options)
      ? field.options
      : field.options[getValues(field?.options?.meta?.scope)];
    if (field.name === "Country__c") {
      customOptions = Country.getAllCountries().map(({ name: n }) => ({
        label: n,
        value: n,
      }));
      let removedElement = customOptions.splice(
        customOptions.findIndex((el) => el.label === "India"),
        1
      )[0];
      customOptions.unshift(removedElement);
    }
    const isStateDisabled = field.name === "State__c" && customOptions.length === 0; 
    const isCityDisabled = field.name === "City__c" && customOptions.length === 0;

    return (
      <>
        <div style={{ display: isCityDisabled || isStateDisabled ? 'none' : 'block', width: isCityDisabled || isStateDisabled || field.name == 'Academic_Year__c' ? '-webkit-fill-available' : '-webkit-fill-available', paddingLeft: field.name == "Country__c" || field.name == "State__c" || field.name == "City__c" || field.name == "Academic_Year__c"  ? '22px' : '', marginTop: field.name == "Country__c" || field.name == "State__c" || field.name == "City__c" || field.name == "Academic_Year__c"  ? '10px' : ''}}>
          <Controller
            name={field.name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <KenSelect
                required={field.required}
                id={field.name}
                name={field.name}
                label={field.label}
                options={customOptions}
                onChange={onChange}
                value={value}
                error={error?.message}
              />
            )}
            {...((isCityDisabled || isStateDisabled) ? {} : {...register(field.name, { ...field.validations })})}
          />
        </div>
      </>
    );
  };

  const renderPhoneInput = (field) => {
    return (
      <Controller
        name={field.name}
        control={control}
        rules={{
          required: 'Field is required',
          validate: phoneValidatonCheck,
          maxLength: {
            value: 17,
            message: 'Please input phone number',
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <KenPhoneInput
            required={field.required}
            name={field.name}
            label={field.label}
            nepalForm={isDataAvailable}
            containerClass="your-phone-container-class"
            inputClass="your-input-class phone-input"
            buttonClass="your-button-class phone-dropdown"
            country={"in"}
            placeholder="Enter Number"
            onChange={(e) => {
              onChange(e);
            }}
            inputType="circular"
            hideLabel="true"
            value={value}
            error={error?.message}
          />
        )}
      />
    );
  };

  const renderInput = (field) => {
    const getPattern = new RegExp(field?.validations?.pattern?.value);
    return (
      <Controller
        name={field.name}
        control={control}
        render={({ field: fieldData, fieldState: { error } }) => {
          return <KenInput error={error?.message} {...field} {...fieldData} />;
        }}
        {...register(field.name, {
          ...field.validations,
          pattern: {
            value: getPattern,
            message: field?.validations?.pattern?.message,
          },
        })}
      />
    );
  };

  const watchLevelOfStudy = watch("lavelOfStudy");
  const watchCountry = watch("Country__c");
  const watchState = watch("State__c");

  useEffect(() => {
    let formDataCopy = [...data];
    if (watchCountry) {
      formDataCopy.find((el) => el.name === "State__c").options =
        State.getStatesOfCountry(
          Country.getAllCountries().find((con) => con.name === watchCountry)
            ?.isoCode
        ).map(({ name }) => ({
          label: name,
          value: name,
        }));
      formDataCopy.find((el) => el.name === "City__c").options = [];
    }
    if (watchState) {
      formDataCopy.find((el) => el.name === "City__c").options =
        City.getCitiesOfState(
          Country.getAllCountries().find((con) => con.name === watchCountry)
            ?.isoCode,
          State.getAllStates().find((state) => state.name === watchState)
            ?.isoCode
        ).map(({ name }) => ({
          label: name,
          value: name,
        }));
    }
    setData(formDataCopy);
  }, [watchCountry, watchState]);

  const qrSpecificFields = [
    {
      name: "Name_of_the_event__c",
      label: "Name of the Event",
      required: true,
      "validations": {
        "required": {
          "value": true,
          "message": "Field is required"
        },
        "maxLength": {
          "value": 100,
          "message": "This field cannot be more than 100 characters"
        },
        "pattern": {
          "value": "",
          "message": "Please Enter Valid Event Name"
        }
      },
    },
    {
      name: "Location__c",
      label: "Location",
      "validations": {
        "maxLength": {
          "value": 100,
          "message": "This field cannot be more than 100 characters"
        },
        "pattern": {
          "value": "",
          "message": "Please Enter Valid Location"
        }
      },
    },
  ];

  return (
    <>
      {loader && <KenLoader />}
      <Grid xs={12} sm={12} md={3}>
        <div
          className="App"
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Grid item xs={0} md={12} style={{ display: isMdScreen ? 'block' : 'none', position: 'absolute', height: '100%', width: '100%', overflowY: 'hidden' }}>
            <img src={images} style={{ height: '100%', width: '100%' }} alt="login" />
          </Grid>
              {showVerifyOTP ? (
                <VerifyOTP
                  data={{ ...getValues(), leadId: Id }}
                  handleBackClick={handleBackClick}
                  email={getValues("Email")}
                />
              ) : (
                <>
                  {isMdScreen && 
                    <Grid position={'absolute'} left={'100px'} top={'100px'}>
                      <Box display={'flex'} alignItems={'center'} textAlign={'left'} color={'#fff'}>
                        <Box> 
                          <img 
                            src={`https://flamestorageken42.blob.core.windows.net/flame-storage-ken42/aims.gif`} 
                            alt="logo"
                            style={{
                              height: "120px",
                              weight: "120px",
                              borderRadius: '10px'
                            }}
                            />
                        </Box>
                        <Box marginLeft={'20px'}>
                          <Typography fontSize={'30px'}>AIMS Institutes</Typography>
                          <Typography>Peenya, Bangalore</Typography>
                        </Box>
                      </Box>
                      <Box marginTop={'20px'} color={'#fff'} textAlign={'left'}>
                        <Typography fontSize={'40px'} fontWeight={'bold'}>Transforming youth into</Typography>
                        <Typography fontSize={'40px'} fontWeight={'bold'}>professionals of global excellence</Typography>
                      </Box>
                      <Box height={'1vh'} width={'5vw'} backgroundColor={'#a2d9d0'} marginTop={'15px'}></Box>
                      <Box color={'#fff'} marginTop={'15px'} textAlign={'start'}>
                        <Typography>AIMS Business School in Peenya, Bangalore offers industry-focused business</Typography>
                        <Typography>programs, equipping students for success in the competitive business landscape.</Typography>
                      </Box>
                    </Grid>
                  }
                  <Grid className={classes.formBox}>
                    <div className="formContainer">
                      <form
                        className={classes.form}
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <PerfectScrollbar>
                          <p style={{ fontSize: "32px", fontWeight: "700 !important", fontFamily: 'serif' }}>
                            Enquiry Form
                          </p>
                            <Grid item container lg={12} spacing={3}>
                            {
                              isDataAvailable ? (
                                nepalFormDataState.map((field) => (
                                  <Grid item lg={12} xs={12} key={field.name} style={{ width: field.name == "Country__c" || field.name == "State__c" || field.name == "City__c" || field.name == "Academic_Year__c" ? '-webkit-fill-available' : 'block', display: field.name == "Country__c" || field.name == "State__c" || field.name == "City__c" || field.name == "Academic_Year__c" ? 'contents' : 'block' }}>
                                    {renderFormField(field)}
                                  </Grid>
                                ))
                              ) : (
                                <>
                                {formData.fields.map((field) => (
                                  <Grid item lg={12} xs={12} key={field.name} style={{ width: field.name == "Country__c" || field.name == "State__c" || field.name == "City__c" || field.name == "Academic_Year__c" ? '-webkit-fill-available' : 'block', display: field.name == "Country__c" || field.name == "State__c" || field.name == "City__c" || field.name == "Academic_Year__c" ? 'contents' : 'block' }}>
                                    {renderFormField(field)}
                                  </Grid>
                                ))}
                                <Grid item lg={12} xs={12} style={{paddingTop: '16px'}}>
                                  {qrForm && qrSpecificFields.map((field, index) => (
                                    <Grid item key={field.name} style={{ marginTop: index === 1 ? '16px' : '0' }}>
                                      {renderFormField(field)}
                                    </Grid>
                                  ))}
                                </Grid>
                                </>
                              )
                            }
                            </Grid>
                          <div style={{ marginTop: "20px" }}>
                            <label style={{ fontSize: "12px" }}>
                              <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                style={{ width: '15px',
                                height: '15px',
                                appearance: 'none',
                                WebkitAppearance: 'none',
                                MozAppearance: 'none',
                                backgroundColor: isChecked ? 'orange' : 'white',
                                border: '1px solid #d55f2c',
                                position: 'relative',
                                top: '0',
                                left: '0',
                                margin: '0',
                                cursor: 'pointer'}} // Adjust the size here
                              />
                              {isChecked && (
                                <svg
                                  width="15px"
                                  height="15px"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ position: 'relative', top: '0', left: '-15px', pointerEvents: 'none', marginRight: '-15px' }}
                                >
                                  <path
                                    d="M9 19L3 12.5L4.5 11L9 15L19 5.5L20.5 7L9 19Z"
                                    fill="white"
                                  />
                                </svg>
                              )}
                              <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                              I hereby authorize AIMS Institutes to contact me and provide notifications and updates via WhatsApp, SMS, phone calls, or email.
                              </span>
                            </label>
                          </div>
                          <KenButton
                            type="submit"
                            variant="primary"
                            // disabled={isChecked === false ? true : false}
                            style={{ margin: "25px", color: "white !important", background: 'linear-gradient(114.33deg, #d55f2c, #8a286d)' }}
                            onClick={() => {
                              const formValues = getValues(); // Get form values
                              console.log(Object.values(formValues), "formValues")
                              const isFormEmpty = Object.values(formValues).some(value => !value);
                              console.log(isFormEmpty, "isFormEmpty")
                              const undefinedCount = Object.values(formValues).filter(value => (value === undefined) || value === '' ).length;
                              console.log(undefinedCount, "undefinedCount");
                              if (isDataAvailable) {
                                if (isFormEmpty && (undefinedCount > 4)) {
                                  enqueueSnackbar("Please fill all required Fields", {
                                    variant: 'info',
                                  });
                                } else {
                                  return;
                                }
                              } else {
                                  if (isFormEmpty && (undefinedCount > 2)) {
                                    enqueueSnackbar("Please fill all required Fields", {
                                      variant: 'info',
                                    });
                                  } else {
                                    return;
                                  }
                                }
                            }}
                          >
                            Get OTP
                          </KenButton>
                        </PerfectScrollbar>
                      </form>
                    </div>
                  </Grid>
                </>
            )}
        </div>
      </Grid>
    </>
  );
}

export default App;
